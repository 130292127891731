import React from "react"
import * as footerStyles from "./Footer.module.scss"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SmartLink from "../SmartLink/SmartLink"
const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFooter {
        nodes {
          gamesList {
            gameCards {
              linkUrl
              gameIcon350w {
                description
                gatsbyImageData(placeholder: BLURRED, width: 350, height: 350)
              }
            }
          }

          pageLinksList {
            title
            slug
          }
          copyrights
        }
      }
    }
  `)
  const {
    gamesList,
    pageLinksList,
    copyrights,
  } = data.allContentfulFooter.nodes[0]

  const gameIcons = gamesList.gameCards.map(gameCard => {
    const { linkUrl, gameIcon350w } = gameCard
    return {
      linkUrl,
      description: gameIcon350w.description,
      gameIcon350w,
    }
  })

  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.icons}>
        {gameIcons.map((item, ind) => {
          const image = getImage(item.gameIcon350w)
          return (
            <div
              key={`footer-game-icon-${ind}`}
              className={footerStyles.gameIcon}
            >
              <SmartLink href={item.linkUrl}>
                <GatsbyImage image={image} alt={item.description} />
              </SmartLink>
            </div>
          )
        })}
      </div>

      <div className={footerStyles.importantInfo}>
        <div className={footerStyles.copyrights}>{copyrights}</div>
        <div className={footerStyles.usefulLinks}>
          <div className={footerStyles.link}>
            <Link to="/terms-of-service#!/terms">Terms of Service</Link>
          </div>
          <div className={footerStyles.link}>
            <Link to="/terms-of-service#!/privacy">Privacy Policy</Link>
          </div>
          <div className={footerStyles.link}>
            <Link to="/terms-of-service#!/cookies">Cookies</Link>
          </div>
          {/* {pageLinksList.map((item, ind) => {
            const { title, slug } = item
            return (
              <div key={`footer-link-${ind}`} className={footerStyles.link}>
                {slug ? <Link to={`/${slug}`}>{title}</Link> : `${item}`}
              </div>
            )
          })} */}
        </div>
      </div>
    </footer>
  )
}

export default Footer
