import React from "react"
import Footer from "../Footer/Footer"
import * as layoutStyles from "./Layout.module.scss"
import CookiesNotification from "../CookiesNotification/CookiesNotification"
import AppHeader from "../AppHeader/AppHeader"
import { isIE } from "react-device-detect"
import ScrollToTop from "../ScrollToTop/ScrollToTop"

const Layout = props => {
  const { backgroundColor } = props

  if (isIE) {
    return (
      <div>
        Hi! Unfortunately, we don't support Internet Explorer Anymore... you can
        try either Chorme, Edge, Firefox, Safari, Opera... Thanks!
      </div>
    )
  }

  return (
    <div className={layoutStyles.container}>
      <CookiesNotification />
      <AppHeader className={layoutStyles.header} />
      <div className={layoutStyles.body} style={{ backgroundColor }}>
        {props.children}
      </div>
      <Footer className={layoutStyles.footer} />
      <ScrollToTop />
    </div>
  )
}

export default Layout
