import React, { useState } from "react"
import { Link } from "gatsby"
import * as headerStyles from "./AppHeader.module.scss"
import MobileNavbar from "../MobileNavbar/MobileNavbar"
import DesktopNavbar from "../DesktopNavbar/DesktopNavbar"
import { useStaticQuery, graphql } from "gatsby"
import SocialLinks from "../SocialLinks/SocialLinks"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const AppHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHeader {
        nodes {
          logo {
            gatsbyImageData(placeholder: BLURRED)
          }
          socialLinks {
            href
            icon {
              svg {
                content
              }
            }
          }
          navigationBarLinks {
            __typename
            ... on ContentfulPage {
              title
              slug
            }
            ... on ContentfulExternalPage {
              title
              url
            }
          }
        }
      }
    }
  `)
  const {
    navigationBarLinks,
    socialLinks,
    logo,
  } = data.allContentfulHeader.nodes[0]
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const toggleMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen)
  const navItems = navigationBarLinks.map(page => {
    if (page.__typename === "ContentfulExternalPage") {
      return {
        name: page.title,
        path: page.url,
        type: "external",
      }
    } else if (page.__typename === "ContentfulPage") {
      return {
        name: page.title,
        path: `/${page.title.toLowerCase() === "home" ? "" : `${page.slug}/`}`,
        type: "internal",
      }
    } else return undefined
  })
  const socialLinksSrcs = socialLinks.map(social => {
    return {
      icon: social.icon.svg.content,
      href: social.href,
    }
  })

  return (
    <header className={headerStyles.headerBar}>
      <MobileNavbar
        navItems={navItems}
        toggleMenuLocation={toggleMenu}
        isMenuOpen={isMobileMenuOpen}
        socialLinks={socialLinksSrcs}
      />
      <div className={headerStyles.logo}>
        <Link to="/">
          <GatsbyImage image={getImage(logo)} alt="SciPlay Logo" />
        </Link>
      </div>
      <DesktopNavbar className={headerStyles.navBar} navItems={navItems} />
      <SocialLinks
        socialLinks={socialLinksSrcs}
        groupClass={headerStyles.socialLinksGroup}
        linkClass={headerStyles.socialLink}
      />
    </header>
  )
}

export default AppHeader
