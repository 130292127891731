import React from "react"
import * as cookiesNotificationStyles from "./CookiesNotification.module.scss"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"
const CookiesNotification = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="x"
      cookieName="gatsby-gdpr-google-analytics"
      buttonClasses={cookiesNotificationStyles.button}
      containerClasses={cookiesNotificationStyles.container}
      // enableDeclineButton
      flipButtons
    >
      <div className={cookiesNotificationStyles.holder}>
        This site uses cookies.
        <Link
          to="/terms-of-service#!/cookies"
          className={cookiesNotificationStyles.policyLink}
        >
          Read our policy.
        </Link>
      </div>
    </CookieConsent>
  )
}
export default CookiesNotification
