import React, { useState, useEffect } from "react"
import * as scrollToTopStyles from "./ScrollToTop.module.scss"
import { ChevronUpIcon } from "@heroicons/react/solid"

const ScrollToTop = () => {
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setScrollToTopVisible(true)
    } else if (scrolled <= 300) {
      setScrollToTopVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", toggleVisible)
    }
  }, [scrollToTop])

  return (
    <div
      className={scrollToTopStyles.scrollUp}
      onClick={scrollToTop}
      style={{ visibility: scrollToTopVisible ? "visible" : "hidden" }}
    >
      <ChevronUpIcon width={35} height={35} />
    </div>
  )
}

export default ScrollToTop
